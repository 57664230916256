/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
    margin: 0;
    font-family: 'Noto Sans JP', sans-serif;
    background: white;
}

.header {
    font-family: 'Zen Maru Gothic', sans-serif;
    text-align: center;
    font-size: xx-large;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 1.3;
}

.japanese-font {
    font-family: 'Zen Maru Gothic', sans-serif;
}

.primary-brown {
    color: #795548;
}

.primary-yellow {
    color: #FFD600
}

.bg-primary-brown {
    background-color: #795548;
}

.bg-primary-yellow {
    background-color: #FFD600
}

.bg-primary-light-yellow {
    background-color: lightyellow;
}

.font-huge {
    font-size: 50px;
}

.font-bigger {
    font-size: 40px;
}

.font-big {
    font-size: 35px;
}

.snackbar-bg-red {
    background-color: #E57373;
}

.snackbar-bg-green {
    background-color: #4DB6AC;
}

.snackbar .mat-simple-snackbar-action {
    color: white;
}

.mt {
    margin-top: 130px;
}
@media (max-width: 501px) {
    .font-huge {
        font-size: 40px;
    }

    .font-bigger {
        font-size: 30px;
    }

    .font-big {
        font-size: 25px;
    }
}

@media (max-width: 401px) {
    .font-huge {
        font-size: 30px;
    }

    .font-bigger {
        font-size: 20px;
    }

    .font-big {
        font-size: 15px;
    }
}
